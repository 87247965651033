@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap"); */

@font-face {
  font-family: 'PolySans';
  src: url('./fonts/PolySans-Median.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.custom-checkbox {
  @apply appearance-none relative h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-seller focus:outline-none;
}

.custom-checkbox:checked {
  @apply bg-seller;
}

.custom-checkbox:checked:focus {
  @apply ring-1 ring-seller;
}

.custom-checkbox:hover {
  @apply border-gray-400;
}

.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0.25rem;
  height: 0.5rem;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.homePage-user:hover button{
background-color: rgba(var(--color-seller));
}

@layer components {
  .homepage-card-wrapper {
    @apply absolute cursor-pointer rounded-lg inset-2 bg-gradient-to-tr duration-500 from-seller/10 to-seller/0 opacity-100 w-[calc(100%-16px)] h-[calc(100%-16px)];
  }

  .searchpage-grid {
    @apply grid grid-cols-1 gap-x-8 gap-y-12 pb-16 sm:gap-y-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-5 md:pb-0;
  }
  .catalog-input {
    @apply h-12 w-full rounded-xl border border-grey2-20 bg-white px-4 pl-10 text-sm text-grey2-60 shadow-sm;
  }
  .content-container {
    @apply mx-auto w-full max-w-[1440px] px-8;
  }

  .contrast-btn {
    @apply rounded-full border border-black px-4 py-2 transition-colors duration-200 ease-in hover:bg-black hover:text-white;
  }

  .text-xsmall-regular {
    @apply text-[10px] font-normal leading-4;
  }

  .text-small-regular {
    @apply text-xs font-normal leading-5;
  }

  .text-small-semi {
    @apply text-xs font-semibold leading-5;
  }

  .text-base-regular {
    @apply text-sm font-normal leading-6;
  }

  .text-base-semi {
    @apply text-sm font-semibold leading-6;
  }

  .text-large-regular {
    @apply text-base font-normal leading-6;
  }

  .text-large-semi {
    @apply text-base font-semibold leading-6;
  }

  .text-xl-regular {
    @apply text-2xl font-normal leading-[36px];
  }

  .text-xl-semi {
    @apply text-2xl font-semibold leading-[36px];
  }

  .text-2xl-regular {
    @apply text-[30px] font-normal leading-[48px];
  }

  .text-2xl-semi {
    @apply text-[30px] font-semibold leading-[48px];
  }

  .text-3xl-regular {
    @apply text-[36px] font-normal leading-[48px];
  }

  .text-3xl-semi {
    @apply text-[36px] font-semibold leading-[48px];
  }
}
